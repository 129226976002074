import React from 'react'
import AdminNavbar from "../Navbars/AdminNavbar";
import Sidebar from "../Sidebar/Sidebar";
import { AxiosResponse } from 'axios';
import { UserApis } from '../../apis/userApi';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { convertUTCDateToLocalDate } from '../Reusable/functions';
import { NavLink } from 'react-router-dom';
//@ts-ignore
import Modal from 'react-awesome-modal';
import { SvgElement, icontypesEnum } from '../assets/svgElement';
import { SuperAdminApis } from '../../apis/superAdminApi';
import { STATUSTYPES } from '../Reusable/Status';
import { CURRENCYCODE } from '../Reusable/CurrencyCode';
import { COUNTRYCODE } from '../Reusable/CountryCode';
import AdminSidebar from '../Sidebar/AdminSidebar';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FaPen } from "react-icons/fa";
import { Oval } from 'react-loader-spinner';
import Select from 'react-select';
import AccountList from './expenseCategory.json'



function ExpenseVoucher() {
  let [showScreen, setShowScreen] = React.useState<any>(1);

  const navigate = useNavigate();

  const userLoginData = useSelector((state: any) => state.data.login.value);

  let [shipmentData, setShipmentData] = React.useState<any>({
    full_name: "", department: "", email: '', description_of_purpose: '',
    category_of_expenses: '', justification_of_request: '', amount_requested: '',
    cost_breakdown: '', hod_email: '', audit_email: '', group_head_email: '',
    finance_email: ''
  });


  const [shipmentInfo, setShipmentInfo] = React.useState<any>([]);

  let [statusModal, setStatusModal] = React.useState<boolean>(false);


  let [hodStatusModal, setHodStatusModal] = React.useState<boolean>(false);
  let [auditStatusModal, setAuditStatusModal] = React.useState<boolean>(false);
  let [groupheadStatusModal, setGroupheadStatusModal] = React.useState<boolean>(false);
  let [financeStatusModal, setFinanceStatusModal] = React.useState<boolean>(false);

  const [img1, setImg1] = React.useState('No selected file');
  const [img12, setImg12] = React.useState('empty');

  function uploadImg1(e: any) {
    let size = (e.target.files[0].size / 1048576.0)
    if (e.target.files && e.target.files[0]) {
      if (size > 4) {
        if (e.target.name == 'uploadImg1') {
          e.target.value = ''
          toast.warn('Image too large')
        }
      }
      if (size <= 4) {
        if (e.target.name == 'uploadImg1') {
          setImg1(e.target.files[0]);
          setImg12(URL.createObjectURL(e.target.files[0]))
        }
      }
    }
  };



  let [approveModal, setApproveModal] = React.useState<boolean>(false);
  let [data, setData] = React.useState<any>([]);
  let [riderModal, setRiderModal] = React.useState<boolean>(false);
  let [loader, setLoader] = React.useState<boolean>(false);

  let [shipmentId, setShipmentId] = React.useState<any>([]);
  let [shipmentStatus, setShipmentStatus] = React.useState<any>('');

  let [creditCustomers, setCreditCustomers] = React.useState<any>([]);
  let [vehicle, setVehicle] = React.useState<any>([]);


  function toggleFinanceStatus(data: any) {
    setData(data)
    setFinanceStatusModal(true)
  }

  function toggleHodStatus(data: any) {
    setData(data)
    setHodStatusModal(true)
  }

  function toggleAuditStatus(data: any) {
    setData(data)
    setAuditStatusModal(true)
  }

  function toggleGroupheadStatus(data: any) {
    setData(data)
    setGroupheadStatusModal(true)
  }

  const updateToReview = React.useCallback((e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    SuperAdminApis.updateToReview(data?.id)
      .then((response: any) => {
        if (response?.data) {
          if (response?.data?.status) {
            toast.success(response?.data?.message);
            setStatusModal(false)
            SuperAdminApis.getAllCreditCustomers('', '').then(
              (response: any) => {
                if (response?.data) {
                  setCreditCustomers(response?.data?.data?.data)
                }
              }
            )
          }
          // console?.log(response?.data)
        }
      }).catch((err: any) => {
        console.log(err);
      })

  }, [shipmentInfo, data, creditCustomers]);


  const approveByHod = React.useCallback((e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoader(true);
    SuperAdminApis.approveByHod(data?.id)
      .then((response: any) => {
        if (response?.data) {
          if (response?.data?.status) {
            toast.success(response?.message);
            setHodStatusModal(false)
            SuperAdminApis.getAllExpenseVoucher('', '').then(
              (response: any) => {
                if (response?.data) {
                  setLoader(false)
                  setCreditCustomers(response?.data?.data)
                }
              }
            )
          }
          // console?.log(response?.data)
        }
      }).catch((err: any) => {
        console.log(err);
      })

  }, [shipmentInfo, hodStatusModal, data, creditCustomers, loader]);



  const rejectByHod = React.useCallback((e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoader(true);
    SuperAdminApis.rejectByHod(data?.id)
      .then((response: any) => {
        if (response?.data) {
          if (response?.data?.status) {
            toast.success(response?.data?.message);
            setHodStatusModal(false)
            SuperAdminApis.getAllExpenseVoucher('', '').then(
              (response: any) => {
                if (response?.data) {
                  setLoader(false);
                  setCreditCustomers(response?.data?.data)
                }
              }
            )
          }
          // console?.log(response?.data)
        }
      }).catch((err: any) => {
        console.log(err);
      })

  }, [shipmentInfo, loader, hodStatusModal, data, creditCustomers]);



  const approveByGrouphead = React.useCallback((e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoader(true);
    SuperAdminApis.approveBygrouphead(data?.id)
      .then((response: any) => {
        if (response?.data) {
          if (response?.data?.status) {
            toast.success(response?.data?.message);
            setGroupheadStatusModal(false)
            SuperAdminApis.getAllExpenseVoucher('', '').then(
              (response: any) => {
                if (response?.data) {
                  setLoader(false)
                  setCreditCustomers(response?.data?.data)
                }
              }
            )
          }
          // console?.log(response?.data)
        }
      }).catch((err: any) => {
        console.log(err);
      })

  }, [shipmentInfo, groupheadStatusModal, data, creditCustomers, loader]);



  const rejectByGrouphead = React.useCallback((e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoader(true);
    SuperAdminApis.rejectByGrouphead(data?.id)
      .then((response: any) => {
        if (response?.data) {
          if (response?.data?.status) {
            toast.success(response?.data?.message);
            setHodStatusModal(false)
            SuperAdminApis.getAllExpenseVoucher('', '').then(
              (response: any) => {
                if (response?.data) {
                  setLoader(false);
                  setCreditCustomers(response?.data?.data)
                }
              }
            )
          }
          // console?.log(response?.data)
        }
      }).catch((err: any) => {
        console.log(err);
      })

  }, [shipmentInfo, loader, groupheadStatusModal, data, creditCustomers]);

  const approveByAudit = React.useCallback((e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoader(true);
    SuperAdminApis.approveByAudit(data?.id)
      .then((response: any) => {
        if (response?.data) {
          if (response?.data?.status) {
            toast.success(response?.data?.message);
            setAuditStatusModal(false)
            SuperAdminApis.getAllExpenseVoucher('', '').then(
              (response: any) => {
                if (response?.data) {
                  setLoader(false)
                  setCreditCustomers(response?.data?.data)
                }
              }
            )
          }
          // console?.log(response?.data)
        }
      }).catch((err: any) => {
        console.log(err);
      })

  }, [shipmentInfo, groupheadStatusModal, data, creditCustomers, loader]);



  const rejectByAudit = React.useCallback((e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoader(true);
    SuperAdminApis.rejectByAudit(data?.id)
      .then((response: any) => {
        if (response?.data) {
          if (response?.data?.status) {
            toast.success(response?.data?.message);
            setAuditStatusModal(false)
            SuperAdminApis.getAllExpenseVoucher('', '').then(
              (response: any) => {
                if (response?.data) {
                  setLoader(false);
                  setCreditCustomers(response?.data?.data)
                }
              }
            )
          }
          // console?.log(response?.data)
        }
      }).catch((err: any) => {
        console.log(err);
      })

  }, [shipmentInfo, loader, auditStatusModal, data, creditCustomers]);


  const disburseByFinance = React.useCallback((e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoader(true);
    SuperAdminApis.disburseByFinance(data?.id)
      .then((response: any) => {
        if (response?.data) {
          if (response?.data?.status) {
            toast.success(response?.data?.message);
            setFinanceStatusModal(false)
            SuperAdminApis.getAllExpenseVoucher('', '').then(
              (response: any) => {
                if (response?.data) {
                  setLoader(false)
                  setCreditCustomers(response?.data?.data)
                }
              }
            )
          }
          // console?.log(response?.data)
        }
      }).catch((err: any) => {
        console.log(err);
      })

  }, [shipmentInfo, financeStatusModal, data, creditCustomers, loader]);


  const handleShipmentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setShipmentData({ ...shipmentData, [e.target.name]: e.target.value });
  }


  React.useEffect(() => {
    const query: any = {
      search: '',
    };
    SuperAdminApis.getAllExpenseVoucher('', '').then(
      (response: AxiosResponse<any>) => {
        if (response?.data) {
          console?.log(response?.data)
          setCreditCustomers(response?.data?.data)
        }
      }
    )
  }, []);


  const [searchText, setSearchText] = React.useState('');

  const paginator = React.useCallback(
    (value: any) => {
      //   setLoader(true);
      let value2 = '';
      if (value !== null) {
        value2 = value;
      } else {
        value2 = ''
      }

      console?.log(value2)

      SuperAdminApis.getAllExpenseVoucher(value2, searchText).then(
        (response: any) => {
          if (response?.data) {
            setCreditCustomers(response?.data?.data);
          }
        }
      ).catch(function (error) {
        console.log(error.response.data);
      })

    }, [shipmentInfo, searchText]);

  function refreshPage() {
    window.location.reload();
  }

  const allOptions = AccountList.map((account:any) => ({
    value: account['Full name'],
    label: account['Full name'],
  }));

  const handleSearchChange = (selectedOption: any) => {
    setShipmentData({ ...shipmentData, 'category_of_expenses': selectedOption?.value });
    // setSearchText(selectedOption ? selectedOption?.value : '');
  };


  const schedule = React.useCallback((e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoader(true)
    const formData = new FormData()
    formData.append('full_name', userLoginData?.data?.first_name)
    formData.append('department', userLoginData?.data?.role)
    formData.append('email', userLoginData?.data?.email)
    formData.append('description_of_purpose', shipmentData?.description_of_purpose)
    formData.append('category_of_expenses', shipmentData?.category_of_expenses)
    formData.append('justification_of_request', shipmentData?.justification_of_request)
    formData.append('amount_requested', shipmentData?.amount_requested)
    formData.append('cost_breakdown', shipmentData?.cost_breakdown)
    formData.append('supporting_document', img1)
    formData.append('hod_email', shipmentData?.hod_email)
    formData.append('audit_email', shipmentData?.audit_email)
    formData.append('group_head_email', shipmentData?.group_head_email)
    formData.append('finance_email', shipmentData?.finance_email)
    SuperAdminApis.createExpenseVoucher(formData)
      .then((response: any) => {
        if (response?.data) {
          if (response?.data?.status) {
            toast.success(response?.data?.message);
            setShowScreen(1)
            setLoader(false)
            refreshPage()
          }
        } else {
          setLoader(false)
          toast.error('something went wrong');
        }

      }).catch((err: any) => {
        console.log(err);
        setLoader(false)
        toast.error('something went wrong');
      })

  }, [shipmentData, showScreen, loader, userLoginData, img1]);

  return (
    <>
      <AdminSidebar title="Expense Voucher" />
      <div className="relative md:ml-64 bg-[#FAFAFA] ">
        <AdminNavbar title="Expense Voucher" />

        <div className={showScreen === 1 ? "block " : "hidden"}>
          <div className=' lg:px-10 pb-6 px-6 pt-5 bg-[#FAFAFA] min-h-screen'>

            <div className='flex justify-end mt-5'>
              <button
                onClick={() => setShowScreen(2)}
                type='button'
                className='bg-[#E04343] rounded-[32px] py-[6px] px-[25px] text-white font-medium'
              >
                + Create
              </button>
            </div>

            <div className={'flex justify-end mt-5'}>

              <div className='flex gap-3'>
                <div className="relative w-full">
                  <div className="absolute inset-y-0 start-0 flex items-center pl-3 pointer-events-none">

                    <svg className="w-4 h-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                    </svg>
                  </div>
                  <input type="text" onChange={(e: any) => setSearchText(e.target.value)} className="bg-white text-gray-900 text-sm rounded-[20px] block w-full pl-10 p-1.5" placeholder="Search orders here..." required />
                </div>

                <button
                  onClick={() => paginator('')}
                  type='button'
                  className={'bg-[#E04343] rounded-[32px] px-[20px] border-red-100 text-sm text-white'}
                >
                  Search
                </button>
              </div>
            </div>
            <hr className='mt-3 bg-[#C4C4C44D]' />

            <div className="relative overflow-x-auto">
              <table className="w-full text-sm text-left rtl:text-right text-gray-500 border-separate border-spacing-y-3">
                <thead className="text-[12px] text-[#808080] bg-[#FAFAFA] ">
                  <tr>

                    <th scope="col" className="px-2 py-2">
                      S/N
                    </th>
                    {/* <th scope="col" className="px-2 py-2">
                      Run&nbsp;ID
                    </th> */}
                    <th scope="col" className="px-2 py-2">
                      Full&nbsp;Name
                    </th>

                    <th scope="col" className="px-2 py-2">
                      Email&nbsp;Address
                    </th>
                    <th scope="col" className="px-2 py-2">
                      Department
                    </th>
                    <th scope="col" className="px-2 py-2">
                      Amount&nbsp;Requested
                    </th>


                    <th scope="col" className="px-2 py-2">
                      Voucger&nbsp;Id
                    </th>

                    <th scope="col" className="px-2 py-2 ">
                      HOD&nbsp;To&nbsp;Approve
                    </th>
                    <th scope="col" className="px-2 py-2 ">
                      Audit&nbsp;To&nbsp;Approve
                    </th>
                    {/* <th scope="col" className="px-2 py-2 ">
                     Audit&nbsp;To&nbsp;Approve
                    </th> */}
                    <th scope="col" className="px-2 py-2 ">
                      Group&nbsp;Head&nbsp;To&nbsp;Approve
                    </th>
                    <th scope="col" className="px-2 py-2 ">
                      Finance&nbsp;To&nbsp;Approve
                    </th>

                    <th scope="col" className="px-2 py-2">
                      Action
                    </th>



                  </tr>
                </thead>


                <tbody className=''>

                  {
                    creditCustomers?.data?.map(
                      (datas: any, index: any) => (
                        <tr className="bg-white  ">

                          <td className="px-4 py-2">
                            {index + 1}
                          </td>

                          {/* <td className="px-6 py-4">
                            {datas?.run_id}
                          </td> */}
                          <td className="px-4 py-4">
                            {datas?.full_name}
                          </td>

                          <td className="px-4 py-4 ">
                            {datas?.email}
                          </td>
                          <td className="px-4 py-4">
                            {datas?.department}
                          </td>

                          <td className="px-4 py-4">
                            {datas?.amount_requested}
                          </td>
                          <td className="px-4 py-4">
                            {datas?.voucher_id}
                          </td>
                          <td className="px-4 py-4 ">
                            <div className='flex justify-start gap-2'>
                              <span className='text-[10px] font-bold'> {datas?.hod_email}</span>
                              <span>
                                {datas?.hod_approval_status == 'approved' ? <p><b style={{ fontWeight: "500", fontSize: '12px', backgroundColor: '#C9F0D0', color: '#51CF66', borderRadius: '10px', padding: '2px 5px' }}>{datas?.hod_approval_status}</b></p> : ''}
                                {datas?.hod_approval_status == 'pending' ? <p><b style={{ fontWeight: "500", fontSize: '12px', backgroundColor: '#FFDDBD', color: '#FF922B', borderRadius: '10px', padding: '2px 5px' }}>{datas?.hod_approval_status}</b></p> : ''}
                                {datas?.hod_approval_status == 'rejected' ? <p><b style={{ fontWeight: "500", fontSize: '12px', backgroundColor: '#fecaca', color: '#E04343', borderRadius: '10px', padding: '2px 5px' }}>{datas?.hod_approval_status}</b></p> : ''}
                              </span>
                            </div>


                          </td>


                          <td className="px-4 py-4 ">
                            <div className='flex justify-start gap-2'>
                              <span className='text-[10px] font-bold'> {datas?.audit_email}</span>
                              <span>
                                {datas?.audit_approval_status == 'approved' ? <p><b style={{ fontWeight: "500", fontSize: '12px', backgroundColor: '#C9F0D0', color: '#51CF66', borderRadius: '10px', padding: '2px 5px' }}>{datas?.audit_approval_status}</b></p> : ''}
                                {datas?.audit_approval_status == 'pending' ? <p><b style={{ fontWeight: "500", fontSize: '12px', backgroundColor: '#FFDDBD', color: '#FF922B', borderRadius: '10px', padding: '2px 5px' }}>{datas?.audit_approval_status}</b></p> : ''}
                                {datas?.audit_approval_status == 'rejected' ? <p><b style={{ fontWeight: "500", fontSize: '12px', backgroundColor: '#fecaca', color: '#E04343', borderRadius: '10px', padding: '2px 5px' }}>{datas?.audit_approval_status}</b></p> : ''}
                              </span>
                            </div>


                          </td>


                          <td className="px-4 py-4">
                            <div className='flex justify-start gap-2'>
                              <span className='text-[10px] font-bold'> {datas?.group_head_email}</span>
                              <span>
                                {datas?.group_head_approval_status == 'approved' ? <p><b style={{ fontWeight: "500", fontSize: '12px', backgroundColor: '#C9F0D0', color: '#51CF66', borderRadius: '10px', padding: '2px 5px' }}>{datas?.group_head_approval_status}</b></p> : ''}
                                {datas?.group_head_approval_status == 'pending' ? <p><b style={{ fontWeight: "500", fontSize: '12px', backgroundColor: '#FFDDBD', color: '#FF922B', borderRadius: '10px', padding: '2px 5px' }}>{datas?.group_head_approval_status}</b></p> : ''}
                                {datas?.group_head_approval_status == 'rejected' ? <p><b style={{ fontWeight: "500", fontSize: '12px', backgroundColor: '#fecaca', color: '#E04343', borderRadius: '10px', padding: '2px 5px' }}>{datas?.group_head_approval_status}</b></p> : ''}
                              </span>
                            </div>


                          </td>


                          <td className="px-4 py-4">
                            <div className='flex justify-start gap-2'>
                              <span className='text-[10px] font-bold'> {datas?.finance_email}</span>
                              <span>
                                {datas?.finance_approval_status == 'completed' ? <p><b style={{ fontWeight: "500", fontSize: '12px', backgroundColor: '#C9F0D0', color: '#51CF66', borderRadius: '10px', padding: '2px 5px' }}>{datas?.finance_approval_status}</b></p> : ''}
                                {datas?.finance_approval_status == 'pending' ? <p><b style={{ fontWeight: "500", fontSize: '12px', backgroundColor: '#FFDDBD', color: '#FF922B', borderRadius: '10px', padding: '2px 5px' }}>{datas?.finance_approval_status}</b></p> : ''}
                                {datas?.finance_approval_status == 'rejected' ? <p><b style={{ fontWeight: "500", fontSize: '12px', backgroundColor: '#fecaca', color: '#E04343', borderRadius: '10px', padding: '2px 5px' }}>{datas?.finance_approval_status}</b></p> : ''}
                              </span>
                            </div>


                          </td>
                          {/* <td className="px-4 py-4">
                            {datas?.advancePaymentDate}
                          </td>
                          <td className="px-4 py-4">
                            {datas?.reviewedBy}
                          </td> */}



                          {/* <td className="px-4 py-4">

                            {datas?.reviewStatus == 'Reviewed' ? <p><b style={{ fontWeight: "500", fontSize: '14px', backgroundColor: '#C9F0D0', color: '#51CF66', borderRadius: '10px', padding: '2px 10px' }}>{datas?.reviewStatus}</b></p> : ''}
                            {datas?.reviewStatus == 'pending' ? <p><b style={{ fontWeight: "500", fontSize: '14px', backgroundColor: '#FFDDBD', color: '#FF922B', borderRadius: '10px', padding: '2px 10px' }}>{datas?.reviewStatus}</b></p> : ''}

                          </td>

                          <td className="px-4 py-4">
                            {datas?.approvedBy}
                          </td>

                          <td className="px-4 py-4">
                            {datas?.approvalStatus == 'Approved' ? <p><b style={{ fontWeight: "500", fontSize: '14px', backgroundColor: '#C9F0D0', color: '#51CF66', borderRadius: '10px', padding: '2px 10px' }}>{datas?.approvalStatus}</b></p> : ''}
                            {datas?.approvalStatus == 'pending' ? <p><b style={{ fontWeight: "500", fontSize: '14px', backgroundColor: '#FFDDBD', color: '#FF922B', borderRadius: '10px', padding: '2px 10px' }}>{datas?.approvalStatus}</b></p> : ''}

                          </td> */}



                          <td className="px-4 py-4 cursor-pointer flex justify-between gap-5">
                            <NavLink to={`/admin/view-expense-voucher/${datas?.id}`}>
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="24" height="24" rx="4" fill="#EBEBEB" />
                                <path d="M12 13.5C12.8284 13.5 13.5 12.8284 13.5 12C13.5 11.1716 12.8284 10.5 12 10.5C11.1716 10.5 10.5 11.1716 10.5 12C10.5 12.8284 11.1716 13.5 12 13.5Z" fill="#3F83F8" />
                                <path d="M15.2536 9.30891C14.2289 8.60625 13.1323 8.25 11.9944 8.25C10.9704 8.25 9.97219 8.55469 9.02742 9.15187C8.07469 9.75539 7.1407 10.8516 6.375 12C6.99422 13.0312 7.84125 14.0916 8.72344 14.6995C9.73547 15.3966 10.8359 15.75 11.9944 15.75C13.1428 15.75 14.2409 15.3968 15.2592 14.7002C16.1557 14.0859 17.0088 13.027 17.625 12C17.0067 10.9821 16.1508 9.92437 15.2536 9.30891ZM12 14.25C11.555 14.25 11.12 14.118 10.75 13.8708C10.38 13.6236 10.0916 13.2722 9.92127 12.861C9.75097 12.4499 9.70642 11.9975 9.79323 11.561C9.88005 11.1246 10.0943 10.7237 10.409 10.409C10.7237 10.0943 11.1246 9.88005 11.561 9.79323C11.9975 9.70642 12.4499 9.75097 12.861 9.92127C13.2722 10.0916 13.6236 10.38 13.8708 10.75C14.118 11.12 14.25 11.555 14.25 12C14.2493 12.5965 14.012 13.1684 13.5902 13.5902C13.1684 14.012 12.5965 14.2493 12 14.25Z" fill="#3F83F8" />
                              </svg>
                            </NavLink>

                            {userLoginData?.email == datas?.hod_email ?
                              <div onClick={() => toggleHodStatus(datas)} className="mt-1 cursor-pointer">
                                <FaPen />
                              </div>
                              :
                              ''
                            }


                            {userLoginData?.email == datas?.audit_email ?
                              <div onClick={() => toggleAuditStatus(datas)} className="mt-1 cursor-pointer">
                                <FaPen />
                              </div>
                              :
                              ''
                            }


                            {userLoginData?.email == datas?.group_head_email ?
                              <div onClick={() => toggleGroupheadStatus(datas)} className="mt-1 cursor-pointer">
                                <FaPen />
                              </div>
                              :
                              ''
                            }

                            {userLoginData?.email == datas?.finance_email ?
                              <div onClick={() => toggleFinanceStatus(datas)} className="mt-1 cursor-pointer">
                                <FaPen />
                              </div>
                              :
                              ''
                            }

                          </td>

                        </tr>
                      )
                    )

                  }

                </tbody>

              </table>
            </div>

            <div className='m-4 flex justify-end mt-20'>
              {
                creditCustomers?.links?.filter(((item: any, idx: any) => idx < 1000)).map(
                  (datas: any, index: any) => (
                    <button onClick={() => paginator(datas?.label == 'Next &raquo;' ? datas?.url?.charAt(datas?.url?.length - 1) : (datas?.label === '&laquo; Previous') ? datas?.url?.charAt(datas?.url?.length - 1) : datas?.label)} disabled={datas?.active} className={'mx-1 py-1 px-2 ' + ((datas?.active == false || datas?.url == null) ? 'bg-gray-300 text-black ' : 'bg-[#E04343] text-white')}>
                      {datas?.label == '&laquo; Previous' ? '< Previous' : (datas?.label === 'Next &raquo;') ? 'Next  >' : datas?.label}
                    </button>
                  )
                )
              }

            </div>

          </div>
        </div>
        <div>
          <form onSubmit={schedule}>
            <div className={showScreen === 2 ? "block " : "hidden"}>
              <div className=' lg:px-10 pb-6 px-6 bg-[#FAFAFA] min-h-screen'>
                {/* <!-- Sender Information --> */}
                <div className="px-6 lg:mt-5 bg-white pt-[24px] rounded-[10px]">

                  <div>

                    <div className="flex flex-col lg:flex-row lg:justify-between max-w-screen-xl mx-auto  mt-5">
                      <div className="relative flex flex-col min-w-0 break-words w-full  ">
                        <div className="flex-auto   py-10 pt-0">
                          <div className="flex flex-wrap ">

                            <div className="w-full lg:w-4/12 lg:pr-4">
                              <div className="relative w-full mb-5">
                                <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                  Full Name <span className=' text-red-600'>*</span>
                                </label>
                                <input
                                  type="text"
                                  disabled
                                  className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-3 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                  placeholder="Company Name"
                                  name="full_name"
                                  defaultValue={userLoginData?.data?.first_name}
                                  onChange={handleShipmentChange}
                                  required
                                />
                              </div>
                            </div>



                            <div className="w-full lg:w-4/12 lg:pr-4">
                              <div className="relative w-full mb-5">
                                <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                  Department <span className=' text-red-600'>*</span>
                                </label>
                                <input
                                  type="text"
                                  className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-3 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                  placeholder="Company Phone Number"
                                  name="department"
                                  disabled
                                  defaultValue={userLoginData?.data?.role}
                                  onChange={handleShipmentChange}
                                  required
                                />
                              </div>
                            </div>



                            <div className="w-full lg:w-4/12 lg:pr-4">
                              <div className="relative w-full mb-5">
                                <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                  E-Mail Address <span className=' text-red-600'>*</span>
                                </label>
                                <input
                                  type="email"
                                  className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-3 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                  placeholder="Company Email"
                                  name="email"
                                  disabled
                                  defaultValue={userLoginData?.data?.email}
                                  onChange={handleShipmentChange}
                                  required
                                />
                              </div>
                            </div>

                            <div className="w-full lg:w-8/12 lg:pr-4">
                              <div className="relative w-full mb-5">
                                <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                  Description Of Purpose <span className=' text-red-600'>*</span>
                                </label>
                                <textarea
                                  className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-3 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                  placeholder="Purpose"

                                  name="description_of_purpose"
                                  onChange={(e: any) => handleShipmentChange(e)}
                                  required
                                />
                              </div>
                            </div>


                            <div className="w-full lg:w-4/12 lg:pr-4">
                              <div className="relative w-full mb-5">
                                <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                  Category Of Expenses <span className=' text-red-600'>*</span>
                                </label>

                                <Select
                                  // className='w-[200px]'
                                  options={allOptions} // Options for the dropdown
                                  // defaultValue={allOptions[0]} // Set default selected value
                                  onChange={handleSearchChange} // Handle on change
                                  classNamePrefix="select" // Add custom class prefix if you need styling
                                  isSearchable // Make the dropdown searchable
                                  placeholder="Select a category"
                                  isClearable
                                  required
                                />
                                {/* <input
                                  type="text"
                                  className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-3 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                  placeholder=""
                                  name="category_of_expenses"
                                  onChange={handleShipmentChange}
                                  required
                                /> */}
                              </div>
                            </div>


                            <div className="w-full lg:w-8/12 lg:pr-4">
                              <div className="relative w-full mb-5">
                                <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                  Justification Of Request
                                </label>
                                <textarea
                                  className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-3 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                  placeholder="Request justification"
                                  name="justification_of_request"
                                  onChange={(e: any) => handleShipmentChange(e)}
                                  
                                />
                              </div>
                            </div>

                            <div className=" w-full lg:w-4/12 lg:pr-4">
                              <div className="relative w-full">
                                <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                  Supporting Document
                                </label>
                                <input id="dropzone-file" onChange={uploadImg1} accept="image/x-png,image/gif,image/jpeg" name='uploadImg1' type="file" className={" mb-2 text-sm text-[#6C757D] font-medium"} />
                              </div>
                              <span className="text-[10px] text-[#dc143c]">File should not be more than 4MB.</span>
                            </div>


                            <div className="w-full lg:w-4/12 lg:pr-4">
                              <div className="relative w-full mb-5">
                                <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                  Amount Requested <span className=' text-red-600'>*</span>
                                </label>
                                <input
                                  type="number"
                                  className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-3 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                  placeholder="NG"
                                  name="amount_requested"
                                  onChange={handleShipmentChange}
                                  required
                                />
                              </div>
                            </div>


                            <div className="w-full lg:w-8/12 lg:pr-4">
                              <div className="relative w-full mb-5">
                                <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                  Cost Breakdown <span className=' text-red-600'>*</span>
                                </label>
                                <textarea
                                  className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-3 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                  placeholder="Breakdown"
                                  name="cost_breakdown"
                                  onChange={(e: any) => handleShipmentChange(e)}
                                  required
                                />
                              </div>
                            </div>

                            <div className="w-full lg:w-4/12 lg:pr-4">
                              <div className="relative w-full">
                                <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                  Head Of Department E-mail <span className=' text-red-600'>*</span>
                                </label>
                                <input
                                  type="text"
                                  className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-3 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                  placeholder=""
                                  name="hod_email"
                                  onChange={handleShipmentChange}
                                  required
                                />
                              </div>
                            </div>


                            <div className="w-full lg:w-4/12 lg:pr-4">
                              <div className="relative w-full">
                                <label className="block mb-2 text-[12px] font-semibold text-[#19213D]">
                                  Audit Email <span className=' text-red-600'>*</span>
                                </label>
                                <input
                                  type="text"
                                  className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-3 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                  placeholder=""
                                  name="audit_email"
                                  onChange={handleShipmentChange}
                                  required
                                />
                              </div>
                            </div>


                            <div className="w-full lg:w-4/12 lg:pr-4">
                              <div className="relative w-full">
                                <label className="block mb-2 text-[12px] font-semibold text-[#19213D]">
                                  Group Head Email <span className=' text-red-600'>*</span>
                                </label>
                                <input
                                  type="text"
                                  className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-3 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                  placeholder=""
                                  name="group_head_email"
                                  onChange={handleShipmentChange}
                                  required
                                />
                              </div>
                            </div>



                            <div className="w-full lg:w-4/12 lg:pr-4">
                              <div className="relative w-full">
                                <label className="block mb-2 text-[12px] font-semibold text-[#19213D]">
                                  Finance Email <span className=' text-red-600'>*</span>
                                </label>
                                <input
                                  type="text"
                                  className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-3 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                  placeholder=""
                                  name="finance_email"
                                  onChange={handleShipmentChange}
                                  required
                                />
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>

                  </div>

                </div>

                {/* <!-- Receiver  Information --> */}


                <div className='flex justify-end mt-6'>

                  <button
                    // onClick={() => setShowScreen(2)}
                    type='submit'
                    disabled={loader}
                    className='bg-[#E04343] rounded-[10px] py-[5px] px-[20px] text-white inline-flex'>
                    <div className="flex justify-center gap-3 ">
                      <span> Create Voucher</span>
                      <Oval
                        visible={loader}
                        height="20"
                        width="20"
                        color="#E04343"
                        secondaryColor="#FCBF94"
                        ariaLabel="oval-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                      />
                    </div>
                  </button>

                </div>



              </div>
            </div>

            {/* <!-- Shipments  Information --> */}

          </form>
        </div>

      </div>

      <section>
        <Modal
          visible={hodStatusModal}
          width="350"
          height="400"
          effect="fadeInUp"
          onClickAway={() => setHodStatusModal(false)}
        >
          <div className=" " style={{ height: '100%', overflow: 'auto' }}>

            <div className="container  bg-[#fff] rounded-lg p-2">

              <div className="px-3">

                {/* <span className="flex justify-around">
                    <h1 className=" text-xs text-red-600" style={{ fontSize: '10px' }}>Link can’t be edited in free plan. <span style={{ color: '#61A24F' }} className="font-bold text-xs">Upgrade to Pro</span></h1>
                  </span> */}
                <span className="flex justify-between px-2 pt-3">
                  <p className='mt-2 font-bold'>Voucher Approval by HOD</p>
                  <p className="cursor-pointer font-bold" onClick={(e) => setHodStatusModal(false)}><SvgElement type={icontypesEnum.CANCEL} /></p>
                </span>

                <div className='my-10 text-[14px] text-gray-600'>
                  Please review the details of the voucher request below carefully before making your decision. Your action will determine the next steps in the approval process.
                </div>
                <form className="pb-4 rounded-lg">
                  <div className="flex justify-between gap-2 pt-4">
                    <button
                      type="button"
                      disabled={loader}
                      onClick={(e: any) => approveByHod(e)}
                      style={{ borderRadius: '50px' }}
                      className=" text-green-700 bg-green-200 focus:ring-4 focus:outline-none focus:ring-grredeen-300 font-medium rounded-lg text-sm w-full px-2 py-2.5 text-center "
                    >
                      Approve
                    </button>

                    <button
                      type="button"
                      disabled={loader}
                      onClick={(e: any) => rejectByHod(e)}
                      style={{ borderRadius: '50px', color: '#F52424' }}
                      className=" text-red-700 bg-red-200 focus:ring-4 focus:outline-none focus:ring-grredeen-300 font-medium rounded-lg text-sm w-full px-2 py-2.5 text-center "
                    >
                      Reject
                    </button>


                  </div>



                  <div >
                    {loader ?
                      <span className="flex justify-center pt-4">
                        <Oval
                          visible={true}
                          height="30"
                          width="30"
                          color="#F52424"
                          secondaryColor=""
                          ariaLabel="oval-loading"
                          wrapperStyle={{}}
                          wrapperClass=""
                        />
                      </span>
                      :
                      <span className="flex justify-center pt-4">
                        <button
                          type="button"
                          onClick={(e) => setHodStatusModal(false)}
                          style={{ borderRadius: '50px' }}
                          className=" text-black   focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm w-full px-2 py-1.5 text-center "
                        >
                          Cancel
                        </button>
                      </span>
                    }
                  </div>




                </form>
              </div>

            </div>

          </div>
        </Modal>
      </section>

      <section>
        <Modal
          visible={auditStatusModal}
          width="350"
          height="400"
          effect="fadeInUp"
          onClickAway={() => setAuditStatusModal(false)}
        >
          <div className=" " style={{ height: '100%', overflow: 'auto' }}>

            <div className="container  bg-[#fff] rounded-lg p-2">

              <div className="px-3">

                {/* <span className="flex justify-around">
                    <h1 className=" text-xs text-red-600" style={{ fontSize: '10px' }}>Link can’t be edited in free plan. <span style={{ color: '#61A24F' }} className="font-bold text-xs">Upgrade to Pro</span></h1>
                  </span> */}
                <span className="flex justify-between px-2 pt-3">
                  <p className='mt-2 text-[13px] font-bold'>Voucher Approval by Audit</p>
                  <p className="cursor-pointer font-bold" onClick={(e) => setAuditStatusModal(false)}><SvgElement type={icontypesEnum.CANCEL} /></p>
                </span>

                <div className='my-10 text-[14px] text-gray-600'>
                  Please review the details of the voucher request below carefully before making your decision. Your action will determine the next steps in the approval process.
                </div>
                <form className="pb-4 rounded-lg" onSubmit={approveByAudit}>
                  <div className="flex justify-between gap-2 pt-4">
                    <button
                      type="button"
                      disabled={loader}
                      onClick={(e: any) => approveByAudit(e)}
                      style={{ borderRadius: '50px' }}
                      className=" text-green-700 bg-green-200 focus:ring-4 focus:outline-none focus:ring-grredeen-300 font-medium rounded-lg text-sm w-full px-2 py-2.5 text-center "
                    >
                      Approve
                    </button>

                    <button
                      type="button"
                      disabled={loader}
                      onClick={(e: any) => rejectByAudit(e)}
                      style={{ borderRadius: '50px', color: '#F52424' }}
                      className=" text-red-700 bg-red-200 focus:ring-4 focus:outline-none focus:ring-grredeen-300 font-medium rounded-lg text-sm w-full px-2 py-2.5 text-center "
                    >
                      Reject
                    </button>


                  </div>



                  <div >
                    {loader ?
                      <span className="flex justify-center pt-4">
                        <Oval
                          visible={true}
                          height="30"
                          width="30"
                          color="#F52424"
                          secondaryColor=""
                          ariaLabel="oval-loading"
                          wrapperStyle={{}}
                          wrapperClass=""
                        />
                      </span>
                      :
                      <span className="flex justify-center pt-4">
                        <button
                          type="button"
                          onClick={(e) => setAuditStatusModal(false)}
                          style={{ borderRadius: '50px' }}
                          className=" text-black   focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm w-full px-2 py-1.5 text-center "
                        >
                          Cancel
                        </button>
                      </span>
                    }
                  </div>




                </form>
              </div>

            </div>

          </div>
        </Modal>
      </section>


      <section>
        <Modal
          visible={groupheadStatusModal}
          width="350"
          height="400"
          effect="fadeInUp"
          onClickAway={() => setGroupheadStatusModal(false)}
        >
          <div className=" " style={{ height: '100%', overflow: 'auto' }}>

            <div className="container  bg-[#fff] rounded-lg p-2">

              <div className="px-3">

                {/* <span className="flex justify-around">
                    <h1 className=" text-xs text-red-600" style={{ fontSize: '10px' }}>Link can’t be edited in free plan. <span style={{ color: '#61A24F' }} className="font-bold text-xs">Upgrade to Pro</span></h1>
                  </span> */}
                <span className="flex justify-between px-2 pt-3">
                  <p className='mt-2 text-[13px] font-bold'>Voucher Approval by Group Head</p>
                  <p className="cursor-pointer font-bold" onClick={(e) => setGroupheadStatusModal(false)}><SvgElement type={icontypesEnum.CANCEL} /></p>
                </span>

                <div className='my-10 text-[14px] text-gray-600'>
                  Please review the details of the voucher request below carefully before making your decision. Your action will determine the next steps in the approval process.
                </div>
                <form className="pb-4 rounded-lg" onSubmit={approveByGrouphead}>
                  <div className="flex justify-between gap-2 pt-4">
                    <button
                      type="button"
                      disabled={loader}
                      onClick={(e: any) => approveByGrouphead(e)}
                      style={{ borderRadius: '50px' }}
                      className=" text-green-700 bg-green-200 focus:ring-4 focus:outline-none focus:ring-grredeen-300 font-medium rounded-lg text-sm w-full px-2 py-2.5 text-center "
                    >
                      Approve
                    </button>

                    <button
                      type="button"
                      disabled={loader}
                      onClick={(e: any) => rejectByGrouphead(e)}
                      style={{ borderRadius: '50px', color: '#F52424' }}
                      className=" text-red-700 bg-red-200 focus:ring-4 focus:outline-none focus:ring-grredeen-300 font-medium rounded-lg text-sm w-full px-2 py-2.5 text-center "
                    >
                      Reject
                    </button>


                  </div>



                  <div >
                    {loader ?
                      <span className="flex justify-center pt-4">
                        <Oval
                          visible={true}
                          height="30"
                          width="30"
                          color="#F52424"
                          secondaryColor=""
                          ariaLabel="oval-loading"
                          wrapperStyle={{}}
                          wrapperClass=""
                        />
                      </span>
                      :
                      <span className="flex justify-center pt-4">
                        <button
                          type="button"
                          onClick={(e) => setHodStatusModal(false)}
                          style={{ borderRadius: '50px' }}
                          className=" text-black   focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm w-full px-2 py-1.5 text-center "
                        >
                          Cancel
                        </button>
                      </span>
                    }
                  </div>




                </form>
              </div>

            </div>

          </div>
        </Modal>
      </section>


      <section>
        <Modal
          visible={financeStatusModal}
          width="350"
          height="400"
          effect="fadeInUp"
          onClickAway={() => setFinanceStatusModal(false)}
        >
          <div className=" " style={{ height: '100%', overflow: 'auto' }}>

            <div className="container  bg-[#fff] rounded-lg p-2">

              <div className="px-3">

                {/* <span className="flex justify-around">
                    <h1 className=" text-xs text-red-600" style={{ fontSize: '10px' }}>Link can’t be edited in free plan. <span style={{ color: '#61A24F' }} className="font-bold text-xs">Upgrade to Pro</span></h1>
                  </span> */}
                <span className="flex justify-between px-2 pt-3">
                  <p className='mt-2 text-[13px] font-bold'>Voucher Approval by Finance</p>
                  <p className="cursor-pointer font-bold" onClick={(e) => setFinanceStatusModal(false)}><SvgElement type={icontypesEnum.CANCEL} /></p>
                </span>

                <div className='my-10 text-[14px] text-gray-600'>
                  Please review the details of the voucher request below carefully before making your decision. Your action will determine the next steps in the approval process.
                </div>
                <form className="pb-4 rounded-lg" onSubmit={disburseByFinance}>
                  <div className="flex justify-center gap-2 pt-4">
                    <button
                      type="button"
                      disabled={loader}
                      onClick={(e: any) => disburseByFinance(e)}
                      style={{ borderRadius: '50px' }}
                      className=" text-green-700 bg-green-200 focus:ring-4 focus:outline-none focus:ring-grredeen-300 font-medium rounded-lg text-sm w-full px-2 py-2.5 text-center "
                    >
                      Disburse
                    </button>




                  </div>



                  <div >
                    {loader ?
                      <span className="flex justify-center pt-4">
                        <Oval
                          visible={true}
                          height="30"
                          width="30"
                          color="#F52424"
                          secondaryColor=""
                          ariaLabel="oval-loading"
                          wrapperStyle={{}}
                          wrapperClass=""
                        />
                      </span>
                      :
                      <span className="flex justify-center pt-4">
                        <button
                          type="button"
                          onClick={(e) => setHodStatusModal(false)}
                          style={{ borderRadius: '50px' }}
                          className=" text-black   focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm w-full px-2 py-1.5 text-center "
                        >
                          Cancel
                        </button>
                      </span>
                    }
                  </div>




                </form>
              </div>

            </div>

          </div>
        </Modal>
      </section>





      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover />
    </>
  )
}

export default ExpenseVoucher