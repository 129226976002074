import { AxiosGlobal } from "../shared/axios";
import { AxiosPromise } from "axios";
import configs from "../../configs";
import { store } from "../../store/store";



export class SuperAdminLiveApi extends AxiosGlobal {


  getlinksCount(): AxiosPromise<Array<any>> {
    return this.axios.get(`${configs.context}/admin/get-admin-stats`, {
      headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
    });
  }

  getAllUsers(pageNo: any, query: any): AxiosPromise<Array<any>> {
    return this.axios.get(`${configs.context}/admin/get-all-customers?page=${pageNo}&search=${query?.search}`, {
      headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
    });
  }

  getAllShipments(pageNo: any, query: any): AxiosPromise<Array<any>> {
    return this.axios.get(`${configs.context}/admin/get-all-shipment?page=${pageNo}&search=${query}`, {
      headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
    });
  }


  getAllShipmentsForExport(query: any): AxiosPromise<Array<any>> {
    return this.axios.get(`${configs.context}/admin/get-all-shipments-for-export?date_from=${query?.date_from}&date_to=${query?.date_to}&destination_state=${query?.destination_state}&shipment_status=${query?.shipment_status}&email=${query?.email}`, {
      headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
    });
  }
  

  getSingleUserShipment(id: any): AxiosPromise<Array<any>> {
    return this.axios.get(`${configs.context}/admin/get-single-user-shipment/${id}`, {
      headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
    });
  }

  updateShipmentData(data: any): AxiosPromise<Array<any>> {
    return this.axios.put(`${configs.context}/shipment/update-shipment/${data?.id}`, data, {
      headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
    });
  }

  updateRider(data: any): AxiosPromise<Array<any>> {
    return this.axios.put(`${configs.context}/admin/update-rider/${data?.id}`, data, {
      headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
    });
  }

  updateFleet(data: any): AxiosPromise<Array<any>> {
    return this.axios.put(`${configs.context}/admin/update-fleet/${data?.id}`, data, {
      headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
    });
  }

  deleteRider(data: any): AxiosPromise<Array<any>> {
    return this.axios.delete(`${configs.context}/admin/delete-rider/${data}`, {
      headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
    });
  }

  deleteFleet(data: any): AxiosPromise<Array<any>> {
    return this.axios.delete(`${configs.context}/admin/delete-fleet/${data}`, {
      headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
    });
  }

  updateShipmentStatus(data: any): AxiosPromise<Array<any>> {
    return this.axios.put(`${configs.context}/shipment/update-shipment-status/${data?.id}`, data, {
      headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
    });
  }


  updateMultipleShipmentStatus(data: any): AxiosPromise<Array<any>> {
    return this.axios.post(`${configs.context}/shipment/update-multiple-shipment-status`, data, {
      headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
    });
  }

  updateShipmentOffice(data: any): AxiosPromise<Array<any>> {
    return this.axios.put(`${configs.context}/shipment/update-shipment-office/${data?.id}`, data, {
      headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
    });
  }

  updateShipmentRider(data: any): AxiosPromise<Array<any>> {
    return this.axios.put(`${configs.context}/shipment/update-shipment-rider/${data?.id}`, data, {
      headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
    });
  }

  deleteShipment(data: any): AxiosPromise<Array<any>> {
    return this.axios.delete(`${configs.context}/admin/delete-shipment/${data}`, {
      headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
    });
  }


  getAllInvoices(pageNo: any, query: any): AxiosPromise<Array<any>> {
    return this.axios.get(`${configs.context}/admin/get-all-invoices?page=${pageNo}&search=${query?.search}&from=${query?.from}&to=${query?.to}`, {
      headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
    });
  }

  sendInvoice(data: any): AxiosPromise<Array<any>> {
    return this.axios.post(`${configs.context}/admin/send-invoice`, data, {
      headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
    });
  }

  getAllRiders(pageNo: any, query: any): AxiosPromise<Array<any>> {
    return this.axios.get(`${configs.context}/admin/get-all-riders?page=${pageNo}&search=${query?.search}`, {
      headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
    });
  }

  getAllFleet(pageNo: any, query: any): AxiosPromise<Array<any>> {
    return this.axios.get(`${configs.context}/admin/get-all-fleet?page=${pageNo}&search=${query?.search}`, {
      headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
    });
  }


  getAllStaffs(pageNo: any, query: any): AxiosPromise<Array<any>> {
    return this.axios.get(`${configs.context}/admin/get-all-staffs?page=${pageNo}&search=${query?.search}`, {
      headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
    });
  }


  //Branch
  getAllBranch(pageNo: any, query: any): AxiosPromise<Array<any>> {
    return this.axios.get(`${configs.context}/admin/get-branch?page=${pageNo}&search=${query?.search}`, {
      headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
    });
  }

  createBranch(data: any): AxiosPromise<any> {
    return this.axios.post(`${configs.context}/admin/create-branch`, data, {
      headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
    });
  }

  updateBranch(data: any): AxiosPromise<Array<any>> {
    return this.axios.put(`${configs.context}/admin/update-branch/${data?.id}`, data, {
      headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
    });
  }

  deleteBranch(data: any): AxiosPromise<Array<any>> {
    return this.axios.delete(`${configs.context}/admin/delete-branch/${data}`, {
      headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
    });
  }

  //Credit Customer
  createCreditCustomer(data: any): AxiosPromise<any> {
    return this.axios.post(`${configs.context}/admin/create-credit-customer`, data, {
      headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
    });
  }

  getAllCreditCustomers(pageNo: any, query: any): AxiosPromise<Array<any>> {
    return this.axios.get(`${configs.context}/admin/get-all-credit-customers?page=${pageNo}&search=${query}`, {
      headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
    });
  }

  getAllExpenseVoucher(pageNo: any, query: any): AxiosPromise<Array<any>> {
    return this.axios.get(`${configs.context}/admin/voucher/get-all-vouchers?page=${pageNo}&search=${query}`, {
      headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
    });
  }


  getSingleCreditCustomers(id: any): AxiosPromise<Array<any>> {
    return this.axios.get(`${configs.context}/admin/get-single-credit-customer/${id}`, {
      headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
    });
  }

  updateCreditCustomer(data: any): AxiosPromise<Array<any>> {
    return this.axios.put(`${configs.context}/admin/update-credit-customer/${data?.id}`, data, {
      headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
    });
  }

  updateVoucher(data: any): AxiosPromise<Array<any>> {
    return this.axios.post(`${configs.context}/admin/voucher/update-voucher`, data, {
      headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
    });
  }

  //Audits
  getAudits(pageNo: any, query: any): AxiosPromise<Array<any>> {
    return this.axios.get(`${configs.context}/admin/get-all-audits?page=${pageNo}&search=${query?.search}`, {
      headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
    });
  }

  createAudit(data: any): AxiosPromise<any> {
    return this.axios.post(`${configs.context}/admin/create-audit`, data, {
      headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
    });
  }


  updateToReview(data: any): AxiosPromise<Array<any>> {
    return this.axios.put(`${configs.context}/admin/review-customer/${data}`, data, {
      headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
    });
  }

  createExpenseVoucher(data: any): AxiosPromise<any> {
    return this.axios.post(`${configs.context}/admin/voucher/create-voucher`, data, {
      headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
    });
  }

  getSingleExpenseVoucher(id: any): AxiosPromise<Array<any>> {
    return this.axios.get(`${configs.context}/admin/voucher/get-single-voucher/${id}`, {
      headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
    });
  }


  approveByHod(data: any): AxiosPromise<Array<any>> {
    return this.axios.put(`${configs.context}/admin/voucher/approve-by-hod/${data}`, data, {
      headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
    });
  }

  rejectByHod(data: any): AxiosPromise<Array<any>> {
    return this.axios.put(`${configs.context}/admin/voucher/reject-by-hod/${data}`, data, {
      headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
    });
  }


  approveByAudit(data: any): AxiosPromise<Array<any>> {
    return this.axios.put(`${configs.context}/admin/voucher/approve-by-audit/${data}`, data, {
      headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
    });
  }

  rejectByAudit(data: any): AxiosPromise<Array<any>> {
    return this.axios.put(`${configs.context}/admin/voucher/reject-by-audit/${data}`, data, {
      headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
    });
  }

  approveBygrouphead(data: any): AxiosPromise<Array<any>> {
    return this.axios.put(`${configs.context}/admin/voucher/approve-by-grouphead/${data}`, data, {
      headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
    });
  }

  rejectByGrouphead(data: any): AxiosPromise<Array<any>> {
    return this.axios.put(`${configs.context}/admin/voucher/reject-by-grouphead/${data}`, data, {
      headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
    });
  }

  disburseByFinance(data: any): AxiosPromise<Array<any>> {
    return this.axios.put(`${configs.context}/admin/voucher/disburse-by-finance/${data}`, data, {
      headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
    });
  }

  // rejectByGrouphead(data: any): AxiosPromise<Array<any>> {
  //   return this.axios.put(`${configs.context}/admin/voucher/reject-by-grouphead/${data}`, data, {
  //     headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
  //   });
  // }


  updateToApproved(data: any): AxiosPromise<Array<any>> {
    return this.axios.put(`${configs.context}/admin/approve-customer/${data}`, data, {
      headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
    });
  }



  //Vehicle
  getAllVehicle(pageNo: any, query: any, id: any): AxiosPromise<Array<any>> {
    return this.axios.get(`${configs.context}/admin/get-vehicle/${id}?page=${pageNo}&search=${query?.search}`, {
      headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
    });
  }

  createVehicle(data: any): AxiosPromise<any> {
    return this.axios.post(`${configs.context}/admin/create-vehicle`, data, {
      headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
    });
  }

  updateVehicle(data: any): AxiosPromise<Array<any>> {
    return this.axios.put(`${configs.context}/admin/update-vehicle/${data?.id}`, data, {
      headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
    });
  }

  deleteVehicle(data: any): AxiosPromise<Array<any>> {
    return this.axios.delete(`${configs.context}/admin/delete-vehicle/${data}`, {
      headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
    });
  }




  getsingleUserData(id: any): AxiosPromise<Array<any>> {
    return this.axios.get(`${configs.context}/admin/get-single-user/${id}`, {
      headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
    });
  }


  calculateRate(data: any): AxiosPromise<any> {
    return this.axios.post(`${configs.context}/calculate-rate`, data, {
      headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
    });
  }

  createRider(data: any): AxiosPromise<any> {
    return this.axios.post(`${configs.context}/admin/create-rider`, data, {
      headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
    });
  }


  createFleet(data: any): AxiosPromise<any> {
    return this.axios.post(`${configs.context}/admin/create-fleet`, data, {
      headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
    });
  }



  //manifest 

  createManifest(data: any): AxiosPromise<any> {
    return this.axios.post(`${configs.context}/admin/manifest/create-manifest`, data, {
      headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
    });
  }


  getAllManifest(pageNo: any, query: any): AxiosPromise<Array<any>> {
    return this.axios.get(`${configs.context}/admin/manifest/get-all-manifest?page=${pageNo}&search=${query?.search}`, {
      headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
    });
  }

  getSingleManifest(id: any): AxiosPromise<Array<any>> {
    return this.axios.get(`${configs.context}/admin/manifest/get-single-manifest/${id}`, {
      headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
    });
  }

  updateManifest(data: any): AxiosPromise<Array<any>> {
    return this.axios.put(`${configs.context}/admin/manifest/update-manifest/${data?.id}`, data, {
      headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
    });
  }



  //run - manifest 

  createRunManifest(data: any): AxiosPromise<any> {
    return this.axios.post(`${configs.context}/admin/run-manifest/create-run-manifest`, data, {
      headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
    });
  }


  getAllRunManifest(pageNo: any, query: any): AxiosPromise<Array<any>> {
    return this.axios.get(`${configs.context}/admin/run-manifest/get-all-run-manifest?page=${pageNo}&search=${query?.search}`, {
      headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
    });
  }

  getSingleRunManifest(id: any, query: any): AxiosPromise<Array<any>> {
    return this.axios.get(`${configs.context}/admin/run-manifest/get-single-run-manifest/${id}?search=${query?.search}`, {
      headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
    });
  }

  updateRunManifest(data: any): AxiosPromise<Array<any>> {
    return this.axios.put(`${configs.context}/admin/run-manifest/update-run-manifest/${data?.id}`, data, {
      headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
    });
  }




  //Stafs
  createStaff(data: any): AxiosPromise<any> {
    return this.axios.post(`${configs.context}/admin/create-staff`, data, {
      headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
    });
  }

  updateStaff(data: any): AxiosPromise<Array<any>> {
    return this.axios.put(`${configs.context}/admin/update-staff/${data?.id}`, data, {
      headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
    });
  }

  deleteStaff(data: any): AxiosPromise<Array<any>> {
    return this.axios.delete(`${configs.context}/admin/delete-staff/${data}`, {
      headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
    });
  }



  getAllCountryFrom(pageNo: any): AxiosPromise<Array<any>> {
    return this.axios.get(`${configs.context}/admin/get-country-from?page=${pageNo}`, {
      headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
    });
  }

  addCountryFrom(data: any): AxiosPromise<any> {
    return this.axios.post(`${configs.context}/admin/create-country-from`, data, {
      headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
    });
  }

  addCountryTo(data: any): AxiosPromise<any> {
    return this.axios.post(`${configs.context}/admin/create-country-to`, data, {
      headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
    });
  }

  addStateFrom(data: any): AxiosPromise<any> {
    return this.axios.post(`${configs.context}/admin/create-state-from`, data, {
      headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
    });
  }

  addLgaFrom(data: any): AxiosPromise<any> {
    return this.axios.post(`${configs.context}/admin/create-lga-from`, data, {
      headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
    });
  }

  addLgaTo(data: any): AxiosPromise<any> {
    return this.axios.post(`${configs.context}/admin/create-lga-to`, data, {
      headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
    });
  }

  addStateTo(data: any): AxiosPromise<any> {
    return this.axios.post(`${configs.context}/admin/create-state-to`, data, {
      headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
    });
  }

  updateCountryFrom(data: any, id: any): AxiosPromise<any> {
    return this.axios.put(`${configs.context}/admin/update-country-from/${id}`, data, {
      headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
    });
  }
  updatestateFrom(data: any, id: any): AxiosPromise<any> {
    return this.axios.put(`${configs.context}/admin/update-state-from/${id}`, data, {
      headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
    });
  }

  updateLgaFrom(data: any, id: any): AxiosPromise<any> {
    return this.axios.put(`${configs.context}/admin/update-lga-from/${id}`, data, {
      headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
    });
  }

  updateLgaTo(data: any, id: any): AxiosPromise<any> {
    return this.axios.put(`${configs.context}/admin/update-lga-to/${id}`, data, {
      headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
    });
  }

  updatestateTo(data: any, id: any): AxiosPromise<any> {
    return this.axios.put(`${configs.context}/admin/update-state-to/${id}`, data, {
      headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
    });
  }

  updateCountryTo(data: any, id: any): AxiosPromise<any> {
    return this.axios.put(`${configs.context}/admin/update-country-to/${id}`, data, {
      headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
    });
  }


  deleteCountryFrom(id: any): AxiosPromise<any> {
    return this.axios.delete(`${configs.context}/admin/delete-country-from/${id}`, {
      headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
    });
  }

  deleteStateFrom(id: any): AxiosPromise<any> {
    return this.axios.delete(`${configs.context}/admin/delete-state-from/${id}`, {
      headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
    });
  }

  deleteLgaFrom(id: any): AxiosPromise<any> {
    return this.axios.delete(`${configs.context}/admin/delete-lga-from/${id}`, {
      headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
    });
  }

  deleteLgaTo(id: any): AxiosPromise<any> {
    return this.axios.delete(`${configs.context}/admin/delete-lga-to/${id}`, {
      headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
    });
  }

  deleteStateTo(id: any): AxiosPromise<any> {
    return this.axios.delete(`${configs.context}/admin/delete-state-to/${id}`, {
      headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
    });
  }

  deleteCountryTo(id: any): AxiosPromise<any> {
    return this.axios.delete(`${configs.context}/admin/delete-country-to/${id}`, {
      headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
    });
  }

  getAllCountryTo(pageNo: any): AxiosPromise<Array<any>> {
    return this.axios.get(`${configs.context}/admin/get-country-to?page=${pageNo}`, {
      headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
    });
  }

  getAllStateFrom(pageNo: any, data: any): AxiosPromise<Array<any>> {
    return this.axios.post(`${configs.context}/admin/get-state-from?page=${pageNo}`, data, {
      headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
    });
  }

  getAllLgaFrom(pageNo: any, data: any): AxiosPromise<Array<any>> {
    return this.axios.post(`${configs.context}/admin/get-lga-from?page=${pageNo}`, data, {
      headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
    });
  }

  getAllLgaTo(pageNo: any, data: any): AxiosPromise<Array<any>> {
    return this.axios.post(`${configs.context}/admin/get-lga-to?page=${pageNo}`, data, {
      headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
    });
  }

  

  uploadCountryToExcel(data: any): AxiosPromise<Array<any>> {
    return this.axios.post(`${configs.context}/upload-excel`, data, {
      headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
    });
  }

  uploadCountryToCreditCustomerExcel(data: any): AxiosPromise<Array<any>> {
    return this.axios.post(`${configs.context}/upload-excel-credit-customer`, data, {
      headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
    });
  }

  uploadLgaToExcel(data: any): AxiosPromise<Array<any>> {
    return this.axios.post(`${configs.context}/upload-lga-to-excel`, data, {
      headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
    });
  }


  uploadLgaTCreditCustomerExcel(data: any): AxiosPromise<Array<any>> {
    return this.axios.post(`${configs.context}/upload-lga-to-excel-credit-customer`, data, {
      headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
    });
  }



  getAllStateTo(pageNo: any, data: any): AxiosPromise<Array<any>> {
    return this.axios.post(`${configs.context}/admin/get-state-to?page=${pageNo}`, data, {
      headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
    });
  }


}