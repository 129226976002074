import React from 'react'
import AdminNavbar from "../Navbars/AdminNavbar";
import Sidebar from "../Sidebar/Sidebar";
import { AxiosResponse } from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//@ts-ignore
import Modal from 'react-awesome-modal';
import { SvgElement, icontypesEnum } from '../assets/svgElement';
import { SuperAdminApis } from '../../apis/superAdminApi';
import { useParams } from 'react-router-dom';
import AdminSidebar from '../Sidebar/AdminSidebar';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FaPen } from "react-icons/fa";
import { Oval } from 'react-loader-spinner';
import { MdDownload } from "react-icons/md";



function EditExpenseVoucher() {
  let [showScreen, setShowScreen] = React.useState<any>(1);

  const navigate = useNavigate();

  const userLoginData = useSelector((state: any) => state.data.login.value);

  let [voucherData, setVoucherData] = React.useState<any>([]);
  let [loader, setLoader] = React.useState<boolean>(false);

  const [img1, setImg1] = React.useState('No selected file');
  const [img12, setImg12] = React.useState('empty');

  function uploadImg1(e: any) {
    let size = (e.target.files[0].size / 1048576.0)
    if (e.target.files && e.target.files[0]) {
      if (size > 4) {
        if (e.target.name == 'uploadImg1') {
          e.target.value = ''
          toast.warn('Image too large')
        }
      }
      if (size <= 4) {
        if (e.target.name == 'uploadImg1') {
          setImg1(e.target.files[0]);
          setImg12(URL.createObjectURL(e.target.files[0]))
        }
      }
    }
  };

  const params: any = useParams();
  let cusId: string = params?.id;


  React.useEffect(() => {
    SuperAdminApis.getSingleExpenseVoucher(cusId).then(
      (response: AxiosResponse<any>) => {
        if (response?.data) {
          setVoucherData(response?.data?.data)
          setImg1(response?.data?.data?.supporting_document)
        }
      }
    )
  }, []);





  let [statusModal, setStatusModal] = React.useState<boolean>(false);
  let [approveModal, setApproveModal] = React.useState<boolean>(false);

  const handleShipmentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setVoucherData({ ...voucherData, [e.target.name]: e.target.value });
  }


  const updateExpenseVoucher = React.useCallback((e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoader(true)
    const formData = new FormData()
    formData.append('full_name', voucherData?.full_name)
    formData.append('department', voucherData?.department)
    formData.append('email', voucherData?.email)
    formData.append('description_of_purpose', voucherData?.description_of_purpose)
    formData.append('category_of_expenses', voucherData?.category_of_expenses)
    formData.append('justification_of_request', voucherData?.justification_of_request)
    formData.append('amount_requested', voucherData?.amount_requested)
    formData.append('cost_breakdown', voucherData?.cost_breakdown)
    formData.append('supporting_document', img1)
    formData.append('hod_email', voucherData?.hod_email)
    formData.append('audit_email', voucherData?.audit_email)
    formData.append('group_head_email', voucherData?.group_head_email)
    formData.append('finance_email', voucherData?.finance_email)
    formData.append('id', voucherData?.id)

    SuperAdminApis.updateVoucher(formData)
      .then((response: any) => {
        if (response?.data) {
          if (response?.data?.status) {
            toast.success(response?.data?.message);
            setLoader(false)
            navigate('/admin/expense-voucher')
          }
          // console?.log(response?.data)
        }
      }).catch((err: any) => {
        setLoader(false)
        console.log(err);
      })

  }, [voucherData, loader,img1]);





  // console?.log(shipmentRider)
  const [searchText, setSearchText] = React.useState('');
  function refreshPage() {
    window.location.reload();
  }

  return (
    <>
      <AdminSidebar title="Credit Customer" />
      <div className="relative md:ml-64 bg-[#FAFAFA] ">
        <AdminNavbar title="Credit Customer" />


        <div>
          <form onSubmit={updateExpenseVoucher}>
            <div >
              <div className=' lg:px-10 pb-6 px-6 bg-[#FAFAFA] min-h-screen'>
                {/* <!-- Sender Information --> */}
                <div className="px-6 lg:mt-5 bg-white pt-[24px] rounded-[10px]">

                  <div>

                    <div className="flex flex-col lg:flex-row lg:justify-between max-w-screen-xl mx-auto  mt-5">
                      <div className="relative flex flex-col min-w-0 break-words w-full  ">
                        <div className="flex-auto   py-10 pt-0">
                          <div className="flex flex-wrap ">

                            <div className="w-full lg:w-4/12 lg:pr-4">
                              <div className="relative w-full mb-5">
                                <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                  Full Name
                                </label>
                                <input
                                  type="text"
                                  disabled
                                  className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-3 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                  placeholder="Company Name"
                                  name="full_name"
                                  defaultValue={voucherData?.full_name}
                                  onChange={handleShipmentChange}
                                  required
                                />
                              </div>
                            </div>



                            <div className="w-full lg:w-4/12 lg:pr-4">
                              <div className="relative w-full mb-5">
                                <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                  Department
                                </label>
                                <input
                                  type="text"
                                  className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-3 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                  placeholder="Company Phone Number"
                                  name="department"
                                  disabled
                                  defaultValue={voucherData?.department}
                                  onChange={handleShipmentChange}
                                  required
                                />
                              </div>
                            </div>



                            <div className="w-full lg:w-4/12 lg:pr-4">
                              <div className="relative w-full mb-5">
                                <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                  E-Mail Address
                                </label>
                                <input
                                  type="email"
                                  className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-3 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                  placeholder="Company Email"
                                  name="email"
                                  disabled
                                  defaultValue={voucherData?.email}
                                  onChange={handleShipmentChange}
                                  required
                                />
                              </div>
                            </div>

                            <div className="w-full lg:w-8/12 lg:pr-4">
                              <div className="relative w-full mb-5">
                                <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                  Description Of Purpose
                                </label>
                                <textarea
                                  className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-3 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                  placeholder="Purpose"
                                  defaultValue={voucherData?.description_of_purpose}
                                  name="description_of_purpose"
                                  onChange={(e: any) => handleShipmentChange(e)}
                                  required
                                />
                              </div>
                            </div>


                            <div className="w-full lg:w-4/12 lg:pr-4">
                              <div className="relative w-full mb-5">
                                <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                  Category Of Expenses
                                </label>
                                <input
                                  type="text"
                                  className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-3 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                  placeholder=""
                                  defaultValue={voucherData?.category_of_expenses}
                                  name="category_of_expenses"
                                  onChange={handleShipmentChange}
                                  required
                                />
                              </div>
                            </div>


                            <div className="w-full lg:w-8/12 lg:pr-4">
                              <div className="relative w-full mb-5">
                                <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                  Justification Of Request
                                </label>
                                <textarea
                                  className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-3 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                  placeholder="Request justification"
                                  name="justification_of_request"
                                  defaultValue={voucherData?.justification_of_request}
                                  onChange={(e: any) => handleShipmentChange(e)}
                                  required
                                />
                              </div>
                            </div>

                            <div className=" w-full lg:w-4/12 lg:pr-4">
                              <div className="relative w-full ">
                                <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                  Supporting Document
                                </label>
                                <div className='flex justify-between'>
                                  <input id="dropzone-file" onChange={uploadImg1} accept="image/x-png,image/gif,image/jpeg" name='uploadImg1' type="file" className={" mb-2 text-sm text-[#6C757D] font-medium"} />

                                  {img1 == "no image" || !img1 ?
                                    ''
                                    :
                                    <a href={img1} target='_blank' className='text-[12px] mt-1 text-blue-500 underline'>
                                      View
                                      {/* <MdDownload size={17} /> */}
                                    </a>
                                  }

                                </div>

                              </div>
                              <span className="text-[10px] text-[#dc143c]">File should not be more than 4MB.</span>
                            </div>


                            <div className="w-full lg:w-4/12 lg:pr-4">
                              <div className="relative w-full mb-5">
                                <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                  Amount Requested
                                </label>
                                <input
                                  type="text"
                                  className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-3 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                  placeholder="NG"
                                  name="amount_requested"
                                  defaultValue={voucherData?.amount_requested}
                                  onChange={handleShipmentChange}
                                  required
                                />
                              </div>
                            </div>


                            <div className="w-full lg:w-8/12 lg:pr-4">
                              <div className="relative w-full mb-5">
                                <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                  Cost Breakdown
                                </label>
                                <textarea
                                  className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-3 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                  placeholder="Breakdown"
                                  name="cost_breakdown"
                                  defaultValue={voucherData?.cost_breakdown}
                                  onChange={(e: any) => handleShipmentChange(e)}
                                  required
                                />
                              </div>
                            </div>

                            <div className="w-full lg:w-4/12 lg:pr-4">
                              <div className="relative w-full">
                                <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                  Head Of Department E-mail
                                </label>
                                <input
                                  type="text"
                                  className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-3 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                  placeholder=""
                                  name="hod_email"
                                  defaultValue={voucherData?.hod_email}
                                  onChange={handleShipmentChange}
                                  required
                                />
                              </div>
                            </div>


                            <div className="w-full lg:w-4/12 lg:pr-4">
                              <div className="relative w-full">
                                <label className="block mb-2 text-[12px] font-semibold text-[#19213D]">
                                  Audit Email
                                </label>
                                <input
                                  type="text"
                                  className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-3 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                  placeholder=""
                                  name="audit_email"
                                  defaultValue={voucherData?.audit_email}
                                  onChange={handleShipmentChange}
                                  required
                                />
                              </div>
                            </div>


                            <div className="w-full lg:w-4/12 lg:pr-4">
                              <div className="relative w-full">
                                <label className="block mb-2 text-[12px] font-semibold text-[#19213D]">
                                  Group Head Email
                                </label>
                                <input
                                  type="text"
                                  className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-3 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                  placeholder=""
                                  name="group_head_email"
                                  defaultValue={voucherData?.group_head_email}
                                  onChange={handleShipmentChange}
                                  required
                                />
                              </div>
                            </div>



                            <div className="w-full lg:w-4/12 lg:pr-4">
                              <div className="relative w-full">
                                <label className="block mb-2 text-[12px] font-semibold text-[#19213D]">
                                  Finance Email
                                </label>
                                <input
                                  type="text"
                                  className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-3 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                  placeholder=""
                                  name="finance_email"
                                  defaultValue={voucherData?.finance_email}
                                  onChange={handleShipmentChange}
                                  required
                                />
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>

                  </div>

                </div>

                {/* <!-- Receiver  Information --> */}


                <div className='flex justify-end mt-6'>
                  <div className='flex space-x-10'>
                    <div>
                      <button
                        // onClick={() => setShowScreen(2)}
                        type='submit'
                        disabled={loader}
                        className='bg-[#E04343] rounded-[40px] py-[5px] px-[20px] text-white inline-flex'>
                        <div className="flex justify-center gap-3 ">
                          <span> Update Voucher</span>
                          <Oval
                            visible={loader}
                            height="20"
                            width="20"
                            color="#E04343"
                            secondaryColor="#FCBF94"
                            ariaLabel="oval-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                          />
                        </div>
                      </button>
                    </div>
                  </div>
                </div>



              </div>
            </div>

            {/* <!-- Shipments  Information --> */}

          </form>
        </div>

      </div>

      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover />
    </>
  )
}

export default EditExpenseVoucher