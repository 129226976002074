import React from 'react'
import AdminNavbar from "../Navbars/AdminNavbar.js";
import Sidebar from "../Sidebar/Sidebar";
import { SuperAdminApis } from '../../apis/superAdminApi';
import { AxiosResponse } from 'axios';
import AdminSidebar from '../Sidebar/AdminSidebar';
import { useSelector, useDispatch } from 'react-redux';
import { Oval } from 'react-loader-spinner';

export default function CalculateRate() {

  const [result, setResult] = React.useState<any>([]);

  const [countryFrom, setCountryFrom] = React.useState<any>([]);
  const [countryTo, setCountryTo] = React.useState<any>([]);
  const [stateFrom, setStateFrom] = React.useState<any>([]);
  const [stateTo, setStateTo] = React.useState<any>([]);
  const [lgaFrom, setLgaFrom] = React.useState<any>([]);

  
  const [lgaTo, setLgaTo] = React.useState<any>([]);

  const [loader, setLoader] = React.useState<boolean>(false);

  const userLoginData = useSelector((state: any) => state.data.login.value);


  const [to, setTo] = React.useState<any>([]);
  const [from, setFrom] = React.useState<any>([]);


  const [countryFromData, setCountryFromData] = React.useState<any>('');
  const [countryToData, setCountryToData] = React.useState<any>('');
  const [stateFromData, setStateFromData] = React.useState<any>('');
  const [stateToData, setStateToData] = React.useState<any>('');
  const [lgaFromData, setLgaFromData] = React.useState<any>('');
  const [lgaToData, setLgaToData] = React.useState<any>('');



  const [weight, SetWeight] = React.useState<any>('0.0');
  const [total, setTotal] = React.useState<any>('0.0');

  const [baseValue, setBaseValue] = React.useState<any>('0.0');
  const [baseWht, setBaseWht] = React.useState<any>('0.0');
  let baseVal = 0;
  let baseWeight = 0;
  let realWeight = 0;

  React.useEffect(() => {
    SuperAdminApis.getAllCountryFrom('').then(
      (response: AxiosResponse<any>) => {
        if (response?.data) {
          setCountryFrom(response?.data?.data)
        }
      }
    )

    SuperAdminApis.getAllCountryTo('').then(
      (response: AxiosResponse<any>) => {
        if (response?.data) {
          setCountryTo(response?.data?.data)
          // console?.log(response?.data?.data)
        }
      }
    )
  }, []);


  const getStateFrom = React.useCallback(
    (id: any) => {
      let data = {
        "country_id": id
      }
      setCountryFromData(id)
      SuperAdminApis.getAllStateFrom('', data).then(
        (response: AxiosResponse<any>) => {
          if (response?.data) {
            setStateFrom(response?.data?.data)

          }
        }
      )

    }, [stateFrom, countryFromData]
  );

  const getStateTo = React.useCallback(
    (id: any) => {
      let data = {
        "country_id": id
      }
      setCountryToData(id)
      SuperAdminApis.getAllStateTo('', data).then(
        (response: AxiosResponse<any>) => {
          if (response?.data) {
            setStateTo(response?.data?.data)

          }
        }
      )

    }, [stateTo, countryToData]
  );

  const getLgaFrom = React.useCallback(
    (id: any) => {
      let data = {
        "state_id": id,
        "country_id": countryFromData
      }
      setStateFromData(id)
      SuperAdminApis.getAllLgaFrom('', data).then(
        (response: AxiosResponse<any>) => {
          if (response?.data) {
            setLgaFrom(response?.data?.data)

          }
        }
      )

    }, [lgaFrom, countryFromData, stateFromData]
  );

  const getLgaTo = React.useCallback(
    (id: any) => {
      let data = {
        "state_id": id,
        "country_id": countryToData
      }
      setStateToData(id)
      SuperAdminApis.getAllLgaTo('', data).then(
        (response: AxiosResponse<any>) => {
          if (response?.data) {
            setLgaTo(response?.data?.data)

          }
        }
      )

    }, [lgaTo, countryToData, stateToData]
  );



  const calculate = React.useCallback((e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoader(true);

    let data = {
      "country_from": countryFromData,
      "country_to": countryToData,
      "state_from": stateFromData,
      "state_to": stateToData,
      "lga_from": lgaFromData,
      "lga_to": lgaToData,
      "weight": weight,

    }
    setStateFromData(data)
    SuperAdminApis.calculateRate(data).then(
      (response: AxiosResponse<any>) => {
        if (response?.data) {
          setLoader(false);
          console?.log(response?.data?.data)
          setResult(response?.data?.data)
        }
      }
    )


    // if (to == 'south_west') {
    //   if (weight >= 0.5 && weight <= 10) {
    //     baseWeight = 450;
    //     baseVal = 2700;
    //     realWeight = weight;
    //   } else if (weight >= 10.5 && weight <= 20) {
    //     baseWeight = 425;
    //     baseVal = 11700;
    //     realWeight = weight - 10;
    //   } else if (weight >= 20.5 && weight <= 30) {
    //     baseWeight = 440;
    //     baseVal = 20200;
    //     realWeight = weight - 20;
    //   } else if (weight >= 30.5 && weight <= 40) {
    //     baseWeight = 420;
    //     baseVal = 29095;
    //     realWeight = weight - 30;
    //   } else if (weight >= 40.5 && weight <= 50) {
    //     baseWeight = 398;
    //     baseVal = 37514;
    //     realWeight = weight - 40;
    //   } else if (weight > 50.5) {
    //     baseWeight = 370;
    //     baseVal = 45531;
    //     realWeight = weight - 50;
    //   }

    //   let calValue = (baseVal + ((realWeight * 2) * baseWeight));
    //   setTotal(calValue);
    //   setBaseWht(baseWeight);
    //   setBaseValue(baseVal);
    // } else if (to == 'lagos') {
    //   if (weight >= 0.5 && weight <= 10) {
    //     baseWeight = 420;
    //     baseVal = 1380;
    //     realWeight = weight;
    //   } else if (weight >= 10.5 && weight <= 20) {
    //     baseWeight = 400;
    //     baseVal = 9780;
    //     realWeight = weight - 10;
    //   } else if (weight >= 20.5 && weight <= 30) {
    //     baseWeight = 445;
    //     baseVal = 17780;
    //     realWeight = weight - 20;
    //   } else if (weight >= 30.5 && weight <= 40) {
    //     baseWeight = 421;
    //     baseVal = 26680;
    //     realWeight = weight - 30;
    //   } else if (weight >= 40.5 && weight <= 50) {
    //     baseWeight = 401;
    //     baseVal = 35100;
    //     realWeight = weight - 40;
    //   } else if (weight > 50.5) {
    //     baseWeight = 380;
    //     baseVal = 43120;
    //     realWeight = weight - 50;
    //   }

    //   let calValue = (baseVal + ((realWeight * 2) * baseWeight));
    //   setTotal(calValue);
    //   setBaseWht(baseWeight);
    //   setBaseValue(baseVal);
    // } else if (to == 'south_east' || to == 'south_south') {
    //   if (weight >= 0.5 && weight <= 10) {
    //     baseWeight = 500;
    //     baseVal = 4000;
    //     realWeight = weight;
    //   } else if (weight >= 10.5 && weight <= 20) {
    //     baseWeight = 485;
    //     baseVal = 14000;
    //     realWeight = weight - 10;
    //   } else if (weight >= 20.5 && weight <= 30) {
    //     baseWeight = 450;
    //     baseVal = 23700;
    //     realWeight = weight - 20;
    //   } else if (weight >= 30.5 && weight <= 40) {
    //     baseWeight = 435;
    //     baseVal = 32605;
    //     realWeight = weight - 30;
    //   } else if (weight >= 40.5 && weight <= 50) {
    //     baseWeight = 414;
    //     baseVal = 41039;
    //     realWeight = weight - 40;
    //   } else if (weight > 50.5) {
    //     baseWeight = 390;
    //     baseVal = 49072;
    //     realWeight = weight - 50;
    //   }

    //   let calValue = (baseVal + ((realWeight * 2) * baseWeight));
    //   setTotal(calValue);
    //   setBaseWht(baseWeight);
    //   setBaseValue(baseVal);
    // } else if (to == 'north') {

    //   if (weight >= 0.5 && weight <= 10) {
    //     baseWeight = 880;
    //     baseVal = 5370;
    //     realWeight = weight;
    //   } else if (weight >= 10.5 && weight <= 20) {
    //     baseWeight = 849;
    //     baseVal = 22970;
    //     realWeight = weight - 10;
    //   } else if (weight >= 20.5 && weight <= 30) {
    //     baseWeight = 801;
    //     baseVal = 39950;
    //     realWeight = weight - 20;
    //   } else if (weight >= 30.5 && weight <= 40) {
    //     baseWeight = 755;
    //     baseVal = 49206;
    //     realWeight = weight - 30;
    //   } else if (weight >= 40.5 && weight <= 50) {
    //     baseWeight = 725;
    //     baseVal = 57960;
    //     realWeight = weight - 40;
    //   } else if (weight > 50.5) {
    //     baseWeight = 700;
    //     baseVal = 66304;
    //     realWeight = weight - 50;
    //   }

    //   let calValue = (baseVal + ((realWeight * 2) * baseWeight));
    //   setTotal(calValue);
    //   setBaseWht(baseWeight);
    //   setBaseValue(baseVal);
    // }


  }, [countryFromData, countryToData,loader, stateFromData, stateToData, lgaToData, lgaFromData, result, weight]);



  return (
    <>
      <AdminSidebar title="Calculate Rate" />
      <div className="relative md:ml-64 bg-[#FAFAFA] ">
        <AdminNavbar title="Calculate Rate" />

        <div className=' lg:px-10 pb-6 px-6 pt-5 bg-[#FAFAFA] min-h-screen'>
          <div className='bg-white border border-[#E2E8F0] rounded-[10px] pt-[60px] pb-[40px] px-[23px]'>
            <div className='flex flex-row gap-5'>
              {/* <div className=' basis-3/12'>

                <div className='mt-8'>
                  <h3 className='text-[16px] font-semibold'>Dimension </h3>
                  <p className='text-[12px] text-[#949DB1]'>Little information you want to write here to let users know wassup </p>
                </div>

                <div className='mt-8'>
                  <h3 className='text-[16px] font-semibold'>Dimension </h3>
                  <p className='text-[12px] text-[#949DB1]'>Little information you want to write here to let users know wassup </p>
                </div>
              </div> */}

              <form onSubmit={calculate}>

                <div className='grid md:grid-cols-4 gap-3'>
                  <div>
                    <h3 className='text-[16px] font-semibold mt-7'>Source Information </h3>
                    {/* <p className='text-[12px] text-[#949DB1]'>Little information you want to write here to let users know wassup </p> */}
                  </div>

                  <div className="w-full">
                    <div className="relative w-full mb-5">
                      <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                        Country <span className='text-red-400'>*</span>
                      </label>
                      <select onChange={(e) => { setFrom(e.target.value); getStateFrom(e.target.value) }} required id="countries" className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px] cursor-pointer  ease-linear transition-all duration-150">
                        <option disabled selected>--Select Country--</option>

                        {(userLoginData?.data?.customer_type == 'credit' ?
                          countryFrom?.data?.filter((data: any) => data?.client_id == userLoginData?.data?.email)?.map((datas: any, index: any) => (
                            <option value={datas?.id}>{datas?.country_name}</option>

                          )
                          )
                          :
                          countryFrom?.data?.filter((data: any) => data?.client_id == null)?.map((datas: any, index: any) => (
                            <option value={datas?.id}>{datas?.country_name}</option>

                          )
                          ))
                        }

                        {/* {countryFrom?.data?.map((datas: any, index: any) => (
                          <option value={datas?.id}>{datas?.country_name}</option>

                        )
                        )} */}

                      </select>
                    </div>
                  </div>

                  <div className="w-full ">
                    <div className="relative w-full mb-5">
                      <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                        State
                      </label>
                      <select onChange={(e) => getLgaFrom(e?.target?.value)} id="countries" className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150">
                        <option selected>--Select State--</option>
                        {stateFrom?.data?.map((datas: any, index: any) => (
                          <option value={datas?.id}>{datas?.state_name}</option>
                        )
                        )}
                      </select>
                    </div>
                  </div>

                  <div className="w-full ">
                    <div className="relative w-full mb-5">
                      <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                        LGA
                      </label>
                      <select onChange={(e) => setLgaFromData(e.target.value)} id="countries" className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150">
                        <option selected>--Select State--</option>
                        {lgaFrom?.data?.map((datas: any, index: any) => (
                          <option value={datas?.id}>{datas?.lga_name}</option>
                        )
                        )}
                      </select>
                    </div>
                  </div>

                </div>

                <div className='grid md:grid-cols-4 gap-3 mt-5 md:mt-1'>
                  <div>
                    <h3 className='text-[16px] font-semibold mt-7'>Destination Information </h3>
                    {/* <p className='text-[12px] text-[#949DB1]'>Little information you want to write here to let users know wassup </p> */}
                  </div>

                  <div className="w-full">
                    <div className="relative w-full mb-5">
                      <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                        Country <span className='text-red-400'>*</span>
                      </label>
                      <select onChange={(e) => { getStateTo(e.target.value) }} required id="countries" className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px] cursor-pointer  ease-linear transition-all duration-150">
                        <option disabled selected>--Select Country--</option>

                        {(userLoginData?.data?.customer_type == 'credit' ?
                          countryTo?.data?.filter((data: any) => data?.client_id == userLoginData?.data?.email)?.map((datas: any, index: any) => (
                            <option value={datas?.id}>{datas?.country_name}</option>

                          )
                          )
                          :
                          countryTo?.data?.filter((data: any) => data?.client_id == null || data?.client_id == "-")?.map((datas: any, index: any) => (
                            <option value={datas?.id}>{datas?.country_name}</option>

                          )
                          ))
                        }

                       

                      </select>
                    </div>
                  </div>

                  <div className="w-full ">
                    <div className="relative w-full mb-5">
                      <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                        State <span className='text-red-400'>*</span>
                      </label>
                      <select required onChange={(e) => getLgaTo(e?.target?.value)} id="countries" className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150">
                        <option disabled selected>--Select State--</option>
                        {stateTo?.data?.map((datas: any, index: any) => (
                          <option value={datas?.id}>{datas?.state_name}</option>
                        )
                        )}
                      </select>
                    </div>
                  </div>

                  <div className="w-full ">
                    <div className="relative w-full mb-5">
                      <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                        LGA
                      </label>
                      <select required onChange={(e) => setLgaToData(e.target.value)} id="countries" className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150">
                        <option disabled selected>--Select State--</option>
                        {lgaTo?.data?.map((datas: any, index: any) => (
                          <option value={datas?.id}>{datas?.lga_name}</option>
                        )
                        )}
                      </select>
                    </div>
                  </div>

                </div>

                <div className='grid md:grid-cols-4 gap-3 mt-5 md:mt-1'>
                  <div>
                    <h3 className='text-[16px] font-semibold mt-7'>Shipment Information </h3>
                    {/* <p className='text-[12px] text-[#949DB1]'>Little information you want to write here to let users know wassup </p> */}
                  </div>

                  <div className="w-full ">
                    <div className="relative w-full mb-5">
                      <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                        Parsel Weight <span className='text-red-400'>*</span>
                      </label>
                      <div className='flex space-x-3'>
                        <div className=' basis-1/3'>
                          <select disabled id="countries" className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150">
                            <option disabled selected>KG</option>
                            <option value="US">KG</option>
                            <option value="CA">Inch</option>

                          </select>
                        </div>
                        <div className=' basis-2/3'>
                          <input
                            type="number"
                            step={'0.5'}
                            className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                            placeholder=""
                            onChange={(e) => SetWeight(e.target.value)}
                            name="f_name"
                            required
                          />
                        </div>

                      </div>

                    </div>
                  </div>

                  {/* <div className="w-full ">
                    <div className="relative w-full mb-5">
                      <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                        Length <span className='text-gray-400 font-thin'>(Optional)</span>
                      </label>
                      <input
                        type="text"
                        className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                        placeholder="--"
                        name="f_name"

                      />
                    </div>
                  </div> */}


                  {/* <div className="w-full">
                    <div className="relative w-full mb-5">
                      <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                        Width <span className='text-gray-400 font-thin'>(Optional)</span>
                      </label>
                      <input
                        type="text"
                        className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                        placeholder="--"
                        name="f_name"

                      />
                    </div>
                  </div> */}





                </div>

                <div className="w-full ">
                  <button type='submit' disabled={loader} className='bg-[#E04343] text-white py-[10px] rounded-[42px] w-full'>
                    <div className="flex justify-center gap-3 ">
                          <span>Calculate</span>
                          <Oval
                            visible={loader}
                            height="20"
                            width="20"
                            color="#E04343"
                            secondaryColor="#FCBF94"
                            ariaLabel="oval-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                          />
                        </div>
                    </button>
                </div>
              </form>



            </div>
          </div>

          <div className='bg-white border border-[#E2E8F0] rounded-[10px] pt-[20px] pb-[10px] px-[23px] mt-[20px]'>
            <h3 className='text-[16px] text-[#263238] font-medium'>Summary</h3>
            <p className='mt-1 text-[13px] text-[#808080]'>The total cost consist of the tax, Insurance and the shipping charge </p>
            <hr className='mt-2 mb-3' />
            <div className='flex justify-between mb-2'>
              <h3 className='text-[#808080] text-[14px]'>Base Cost </h3>
              <h4 className='text-[#808080] text-[14px]'> {(new Intl.NumberFormat('en-US', { style: 'currency', currency: 'NGN' }).format((result?.base_value) ? (parseInt(result?.base_value) + parseInt(result?.cost_per_kg) ) : 0.0))} </h4>
            </div>
            <div className='flex justify-between mb-2'>
              <h3 className='text-[#808080] text-[14px]'>Cost Per 0.5 KG</h3>
              <h4 className='text-[#808080] text-[14px]'> {(new Intl.NumberFormat('en-US', { style: 'currency', currency: 'NGN' }).format(((result?.cost_per_kg)) ? ((result?.cost_per_kg)) : 0.0))} </h4>
            </div>
            <div className='flex justify-between mb-2'>
              <h3 className='text-[#808080] text-[14px]'>Total Weight</h3>
              <h4 className='text-[#808080] text-[14px]'>{result?.total_weight ? result?.total_weight : "0.0"}</h4>
            </div>

            <div className='flex justify-between mb-2'>
              <h3 className='text-[#808080] text-[14px]'>L.G.A Charges</h3>
              <h4 className='text-[#808080] text-[14px]'>  {(new Intl.NumberFormat('en-US', { style: 'currency', currency: 'NGN' }).format(((result?.lga_charges)) ? ((result?.lga_charges)) : 0.0))} </h4>
            </div>

            <div className='flex justify-between mb-2'>
              <h3 className='text-[#808080] text-[14px]'>Tax</h3>
              <h4 className='text-[#808080] text-[14px]'> 7.5%</h4>
            </div>


            <hr className='mt-4 mb-3' />
            <div>
              <div className='flex justify-between mb-2'>
                <h3 className='text-[#E04343] text-[14px]'>Total</h3>
                <h4 className='text-[#E04343] text-[24px] font-bold'> {(new Intl.NumberFormat('en-US', { style: 'currency', currency: 'NGN' }).format((result?.total_cost) ? (result?.total_cost) : 0.0))}</h4>
              </div>
            </div>
          </div>
        </div>


      </div>

    </>
  )
}
